import { Component, OnInit } from '@angular/core';
import { TodoService } from '../../services/todo.service';

import { Todo } from '../../models/Todo';

@Component({
  selector: 'app-todos',
  templateUrl: './todos.component.html',
  styleUrls: ['./todos.component.css']
})
export class TodosComponent implements OnInit {
  todos: Todo[];

  constructor(private todoService: TodoService) { }

  ngOnInit() {
    const todoList = JSON.parse(localStorage.getItem('todo')) || [];
    this.todos = todoList
  }

  deleteTodo(todo: Todo) {
    const filter = this.todos.filter(t => t.title !== todo.title);
    this.todos = filter
    localStorage.setItem('todo', JSON.stringify(filter));
  }

  addTodo(todo: Todo) {
    const todoList = JSON.parse(localStorage.getItem('todo')) || [];
    todoList.push(todo);
    localStorage.setItem('todo', JSON.stringify(todoList));
    this.todos = todoList
  }

}
